import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import config from "../../../data/SiteConfig"

function SEO({ description, lang, meta, image: metaImage, title, pathname, pageType, children }) {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "babyschlafexpertin_share.jpg" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `
  )

  const metaDescription = description || config.description
  const canonical = pathname ? `${config.siteUrl}/${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={(title === config.title) ? `babyschlafexpertin Micki Weinbir` :`%s | ${config.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical
              }
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: config.keywords.join(","),
        },
        {
          property: `og:title`,
          content: `${config.organization} ${config.author}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: `${config.siteUrl}${image.childImageSharp.resize.src}`,
        },
        {
          property: "og:image:width",
          content: image.childImageSharp.resize.width,
        },
        {
          property: "og:image:height",
          content: image.childImageSharp.resize.height,
        },
        {
          property: "og:locale",
          content: "de_DE"
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: `twitter:creator`,
          content: config.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
          canonical
            ? [{
              property: `og:url`,
              content: canonical,
            },]
            : []
      ).concat(meta)}
    >
      <script type={`application/ld+json`}>{`
        {
          "@context": "https://schema.org/",
          "@type": "${pageType ?? `WebSite`}",
          "author": {
            "@type": "Person",
            "givenName": "${config.authorFirstName}",
            "additionalName": "${config.authorAltName}",
            "familyName": "${config.authorLastName}",
            "affiliation": {
              "@type": "Organization",
              "name": "${config.organization} ${config.author}"
            },
            "email": "${config.email}"
          },
          "abstract": "${config.description}",
          "url": "${config.siteUrl}",
          "headline": "${config.organization}",
          "keywords": [${config.keywords.map(entry => `"${entry}"`)}]
        }
      `}</script>
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
